import { Text, UnstyledButton } from '@akin/ui-lib';
import { createStyles } from '@mantine/core';

const useStyles = createStyles((theme) => ({
  root: {
    overflowY: 'auto',
    // ...theme.other.hideScrollBar,
    ...theme.other.customScrollbar,
    padding: '0px 10px',
    paddingTop: 10,
    backgroundColor: '#f3f4f6',
    marginBottom: 6,
    height: 53,
  },
  container: {
    // maxWidth: 'calc(100% + 150px)',
    display: 'flex',
    gap: 2,
    alignItems: 'center',
    borderRadius: 8,
    visibility: 'visible',
    width: 'fit-content',
    [theme.fn.smallerThan('xs')]: {
      '&.isCollapsed': {
        visibility: 'hidden',
      },
    },
  },
  tab: {
    display: 'flex',
    alignItems: 'center',
    gap: 5,
    borderRadius: 20,
    padding: '5px 10px',
    backgroundColor: '#eae9ea',
    color: '#000',
    '&.active': {
      backgroundColor: theme.colors['theme-color'][6],
      color: '#FFFFFF',
    },
    '&.active > *': {
      fontWeight: 600,
    },
  },
  tabValue: {
    backgroundColor: '#FFFFFF',
    minWidth: 40,
    height: 16,
    borderRadius: 32,
    textAlign: 'center',
    padding: '0 5px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 600,
    '&.active': {
      color: theme.colors['theme-color'][6],
    },
  },
}));

function ConversationTab({ tab, onClick, active }) {
  const { classes, cx } = useStyles();
  return (
    <UnstyledButton
      key={tab.key}
      className={cx(classes.tab, { active })}
      onClick={onClick}
      aria-hidden
    >
      <Text size={11}>{tab.label}</Text>
      <div className={cx(classes.tabValue, { active })}>
        <Text size={11}>{tab.value}</Text>
      </div>
    </UnstyledButton>
  );
}

function ConversationTabs(props) {
  const { children, isCollapsed } = props;
  const { classes, cx } = useStyles();
  return (
    <div className={classes.root}>
      <div className={cx(classes.container, { isCollapsed })}>{children}</div>
    </div>
  );
}

ConversationTabs.Content = ConversationTab;

export default ConversationTabs;
