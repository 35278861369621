import { Switch, createStyles } from '@mantine/core';

const useStyles = createStyles((theme, { showOffColor }) => ({
  root: {
    flexDirection: 'row-reverse',
    justifyContent: 'space-between',
    '& .mantine-Switch-label': {
      padding: 0,
    },

    '& .mantine-Switch-input': {
      backgroundColor: theme.colors.gray[8],
      '&:checked': {
        backgroundColor: theme.colors.blue,
      },

      ...(showOffColor && {
        '&:not(:checked)+.mantine-Switch-track': {
          backgroundColor: theme.colors.red[9],
          color: theme.white,
        },
      }),
    },
  },
}));

export default function SwitchComponent(props) {
  const { classes } = useStyles({ showOffColor: props?.showOffColor });
  return <Switch className={classes.root} {...props} />;
}
