import React from 'react';
import { Tooltip, UnstyledButton, createStyles } from '@mantine/core';
import { Image, Text } from '@akin/ui-lib';

const useStyles = createStyles((theme, { isTagEmpty }) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    padding: '15px 10px',
    justifyContent: 'flex-start',
    borderRadius: 6,
    '&.active': {
      backgroundColor: '#f3f4f6',
    },
    '&.active:hover': {
      backgroundColor: '#f3f4f6',
    },
    '&:hover': {
      backgroundColor: '#dbfbff59',
    },
  },
  content: {
    overflow: 'hidden',
    flexGrow: 1,
  },
  headerName: {
    ...(!isTagEmpty && { maxWidth: '37%' }),
    display: 'flex',
    gap: 4,
  },
  icon: {
    // width: '7%',
  },
  tagContainer: {
    flex: 1,
    display: 'flex',
  },
  tag: {
    alignItems: 'center',
    borderRadius: 5,
    padding: '2px 8px',
    color: '#247387',
    border: '1px solid #cddee2',
    // backgroundColor: '#f7f7f7',

    '& .label': {
      fontSize: 9,
      // letterSpacing: 0.5,
      // fontWeight: 500,
    },
    // [theme.fn.smallerThan('md')]: {
    //   display: 'none',
    // },

    '@media screen and (min-width: 575px) and (max-width: 1000px)': {
      display: 'none',
    },
  },

  time: {
    maxWidth: '31%',
    // [theme.fn.smallerThan('sm')]: {
    //   display: 'none',
    // },
  },
  children: {
    [theme.fn.smallerThan('sm')]: {
      display: 'none',
    },
  },
  info: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: 14,
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    gap: 4,
  },
  titleTag: {
    display: 'flex',
    alignItems: 'center',
    width: '70%',
  },
  infoContent: {},
  messageCount: {
    backgroundColor: '#088bab',
    color: theme.white,
    borderRadius: '50%',
    minWidth: 18,
    height: 18,
    textAlign: 'center',
    fontSize: 11,
    fontWeight: 600,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  name: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: 'block',
    maxWidth: 135,
  },
}));

export default function Conversation({
  name,
  info,
  channel,
  // channelColorStyle,
  channelIcon,
  spaceName,
  className,
  active,
  dateTime,
  timeAgoDateTime,
  onClick,
  messageCount,
  showMessageCountNotification = false,
  children,
}) {
  const { classes, cx } = useStyles({ isTagEmpty: !spaceName });

  return (
    <UnstyledButton
      className={cx(classes.container, { active }, className)}
      onClick={onClick}
    >
      <div className={classes.children}>{children}</div>

      {(name || info) && (
        <div className={cx(classes.content, className)}>
          <div className={classes.header}>
            <div className={classes.headerName}>
              <Text
                weight={700}
                size={12}
                className={classes.name}
                transform="capitalize"
              >
                {name}
              </Text>
              {channelIcon && (
                <div className={classes.icon}>
                  <Image
                    alt={channel}
                    src={channelIcon}
                    width={18}
                    height={18}
                  />
                </div>
              )}
            </div>

            {spaceName && (
              <div className={cx(classes.tagContainer)}>
                <div className={cx(classes.tag)}>
                  <Tooltip label={spaceName} fz={12}>
                    <Text className="label" lineClamp={1}>
                      {spaceName}
                    </Text>
                  </Tooltip>
                </div>
              </div>
            )}

            <div className={classes.time}>
              <Tooltip label={dateTime} fz={12}>
                <Text size={10} color="dimmed" ta="right">
                  {timeAgoDateTime}
                </Text>
              </Tooltip>
            </div>
          </div>

          <div className={classes.info}>
            {!!info && (
              <Text
                size="xs"
                weight={500}
                className={classes.infoContent}
                color={showMessageCountNotification > 0 ? 'black' : 'dimmed'}
                lineClamp={1}
              >
                {info}
              </Text>
            )}

            {showMessageCountNotification > 0 && (
              <div className={classes.messageCount}>{messageCount || ''}</div>
            )}
          </div>
        </div>
      )}
    </UnstyledButton>
  );
}
