import { createStyles } from '@mantine/core';

const useStyles = createStyles((theme) => ({
  container: {
    position: 'relative',
    height: 'calc(100vh - 60px)',
    overflow: 'auto',
    boxSizing: 'border-box',
    backgroundColor: theme.white,
    display: 'flex',
    borderRadius: '8px 8px 0px 0px',
    // flexDirection: 'row',
    // padding: 0,
    // gap: 10,
    [theme.fn.smallerThan('xs')]: {
      height: 'calc(100vh - 100px)',
      margin: '-1rem',
    },
  },
}));

export default function MainContainer({ rest, children, className }) {
  const { classes, cx } = useStyles();
  return (
    <div {...rest} className={cx(classes.container, className)}>
      {children}
    </div>
  );
}
