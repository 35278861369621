const theme = {
  // Defines color scheme for all components, defaults to "light"
  colorScheme: 'light',

  // Controls focus ring styles:
  // auto – display focus ring only when user navigates with keyboard (default)
  // always – display focus ring when user navigates with keyboard and mouse
  // never – focus ring is always hidden (not recommended)
  focusRing: 'auto',

  // Determines whether elements that do not have pointer cursor by default
  // (checkboxes, radio, native select) should have it
  cursorType: 'pointer',

  // Default border-radius used for most elements
  // defaultRadius: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | number;
  defaultRadius: 'sm',

  // theme.white used for body and overlays (Modal, Popover)
  // background when theme.colorScheme is light, default value is #fff
  white: '#fff',

  // theme.black used as color for all text when theme.colorScheme is light,
  // default value is #000
  black: '#000',

  // Object of arrays with 10 colors
  // Use color generator to create color array (https://omatsuri.app/color-shades-generator)
  colors: {
    platformBodyColor: '#f3f4f6',
    publicBodyColor: { light: '#FFF', dark: '#000000' },
    // calendar colors
    blueNavy: ['#4543A6'],
    redMedium: '#ad496d',
    greenMedium: '#579276',
    purpleLight: ['#756ebb'],
    beige: '#F7F3EF',
    'theme-color': [
      '#dbfbff',
      '#b0efff',
      '#82e3fb',
      '#54d8f7',
      '#2cccf4',
      '#1ab3db',
      '#088bab',
      '#00637b',
      '#003d4b',
      '#00161c',
    ],

    // picker colors
    pickerOrange: '#756EBF',
    pickerYellow: '#4172BB',
    pickerLightGreen: '#EB522F',
    pickerGreen: '#DBAF52',
    pickerLightBlue: '#133C55',
    pickerBlue: '#0693E3',
    pickerGrey: '#ABB8C3',
    pickerRed: '#EB144C',
    pickerPink: '#F78DA7',
    pickerPurple: '#9900EF',
    pickerBrown: '#795548',
    pickerPaleBlue: '#00BCD4',

    errorRed: '#fa5252',
    borderColor: {
      var1: '#E0E0E0',
      var2: '#333',
      var3: '#979797',
      var4: '#adb5bd',
    },
    grayLight: '#dedede',
  },

  // theme.primaryColor cannot be a hex, rgb or other color value,
  // it should only reference value from theme.colors
  primaryColor: 'theme-color',

  // Sizes for corresponding properties
  fontSizes: {
    xs: 12,
    sm: 14,
    md: 16,
    lg: 18,
    xl: 20,
  },

  radius: {
    xs: 2,
    sm: 4,
    md: 8,
    lg: 16,
    xl: 32,
  },

  // Used for padding and margins properties
  spacing: {
    xs: 10,
    sm: 12,
    md: 16,
    lg: 20,
    xl: 24,
  },

  other: {
    pageContainerStyle: (theme) => ({
      padding: '4rem',
      maxWidth: '1200px',
      [theme.fn.smallerThan('sm')]: {
        padding: 'unset',
      },
    }),
    sidebarAnimation: {
      transitionDuration: '0.22s',
      transitionProperty: 'left,opacity,width',
      transitionTimingFunction: 'cubic-bezier(0.1,0,0,1)',
    },
    boxShadow: {
      cards:
        'rgba(0, 0, 0, 0.05) 0px 1px 3px, rgba(0, 0, 0, 0.05) 0px 28px 23px -7px, rgba(0, 0, 0, 0.04) 0px 12px 12px -7px',
      summary: 'rgb(100 100 100 / 40%) 0px 6px 16px',
      summaryDark: 'rgb(100 100 100 / 12%) 0px 6px 16px',
    },
    hideScrollBar: {
      /* Hide scrollbar for Chrome, Safari and Opera */
      '&::-webkit-scrollbar': {
        display: 'none',
      },
      msOverflowStyle: 'none' /* IE and Edge */,
      scrollbarWidth: 'none' /* Firefox */,
    },
    customScrollbar: {
      scrollbarWidth: 7,
      scrollbarColor: `#eeeeee`,
      '&::-webkit-scrollbar': {
        width: 7,
        height: 7,
      },
      '&::-webkit-scrollbar-track': {
        // backgroundColor: '#eeeeee',
        backgroundColor: 'transparent',
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#efefef',
        borderRadius: 20,
      },
      '&::-webkit-scrollbar-thumb:hover': {
        background: '#c7c7c7',
      },
    },
  },

  // font-family and line-height used in most components
  // fontFamily: 'Inter,system-ui,-apple-system,sans-serif',
  // fontFamily: string;
  // lineHeight: string | number;

  // h1-h6 styles, used in Title and TypographyStylesProvider components
  // headings: {
  //   fontFamily: CSSProperties['fontFamily'];
  //   fontWeight: CSSProperties['fontWeight'];
  //   sizes: {
  //     // See heading options below
  //     h1: { fontSize: 30 },
  //     h2: {},
  //     h3: {},
  //     h4: {},
  //     h5: {},
  //     h6: {},
  //   }
  // }

  // Values used for box-shadow
  // shadows: {
  //   md: '1px 1px 3px rgba(0, 0, 0, .25)',
  //   xl: '5px 5px 3px rgba(0, 0, 0, .25)',
  // },

  // Breakpoints used in some components to add responsive styles
  // breakpoints: Record<'xs' | 'sm' | 'md' | 'lg' | 'xl', number>;

  // Index of color from theme.colors that is considered primary, Shade type is 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9
  // primaryShade: Shade | { light: Shade; dark: Shade };

  // Default gradient used in components that support `variant="gradient"` (Button, ThemeIcon, etc.)
  // defaultGradient: { deg: number; from: MantineColor; to: MantineColor };
};

export default theme;
