import React from 'react';
import { createStyles } from '@mantine/core';
import { Text } from '@akin/ui-lib';

const useStyles = createStyles(() => ({
  container: {
    padding: '0px 15px',
    height: 60,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'stretch',
    // backgroundColor: '#FFFFFF',
    borderRadius: '4px 0 4px 0',
  },
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: 10,
  },
  flexBox: {
    display: 'flex',
    alignItems: 'center',
    gap: 10,
  },
}));

function ChatHeaderContent({ title, children }) {
  const { classes } = useStyles();
  return (
    <div className={classes.headerContainer}>
      <Text weight={600} mr={10} size="xl">
        {title}
      </Text>
      <div className={classes.flexBox}>{children}</div>
    </div>
  );
}

function ChatHeader(props) {
  const { children } = props;
  const { classes } = useStyles();
  return <div className={classes.container}>{children}</div>;
}

ChatHeader.Content = ChatHeaderContent;

export default ChatHeader;
