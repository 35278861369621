import { Button, Modal, Text, Title } from '@akin/ui-lib';
import { Trans } from '@lingui/macro';
import React, { useEffect, useState } from 'react';
import useConfirm from '../../hooks/useConfirm';

export default function ConfirmDialog() {
  const { onConfirm, onCancel } = useConfirm();
  const [confirmState, setConfirmState] = useState({
    open: false,
    title: '',
    description: '',
    size: 'md',
  });

  const confirmHandler = (e) => {
    setConfirmState({ ...e.detail });
  };

  useEffect(() => {
    document.addEventListener('confirmModal', confirmHandler);
    return () => {
      document.removeEventListener('confirmModal', confirmHandler);
    };
  }, []);

  return (
    <Modal
      opened={confirmState.open}
      onClose={onCancel}
      disableMobileFullScreen
      title={<Title order={4}>{confirmState.title}</Title>}
      size={confirmState.size}
    >
      <Text mb="xl">{confirmState.description}</Text>
      <Button onClick={onConfirm}>
        <Trans>Confirm</Trans>
      </Button>
      <Button ml={10} variant="default" color="light" onClick={onCancel}>
        <Trans>Cancel</Trans>
      </Button>
    </Modal>
  );
}
