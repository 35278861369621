var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"hMbnKmiYMtJDl-2FPGWkz"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from '@sentry/nextjs';
// import * as FullStory from '@fullstory/browser';
// import SentryFullStory from '@sentry/fullstory';

const SENTRY_DSN = process.env.NEXT_PUBLIC_SENTRY_DSN;

// FullStory.init({ orgId: process.env.NEXT_PUBLIC_FULLSTORY_ORG_ID });

Sentry.init({
  environment: process.env.NEXT_PUBLIC_SENTRY_ENV,
  dsn: SENTRY_DSN,
  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
  // integrations: [new SentryFullStory(process.env.NEXT_PUBLIC_SENTRY_ORG_SLUG)],
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
  beforeSendTransaction(event) {
    if (
      event.transaction === '/' ||
      event.transaction === '/__nextjs_original-stack-frame'
    ) {
      return null;
    }
    return event;
  },
});
