import { createStyles, Burger } from '@mantine/core';

const useStyles = createStyles((theme) => ({
  mobileWrapper: {
    display: 'block',
    position: 'fixed',
    zIndex: 12, // as home page filters has z-index 11
    width: '100%',
    [theme.fn.largerThan(830)]: {
      display: 'none',
    },
  },
  mobileHeader: {
    padding: '0.5rem 1rem',
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: theme.white,
    alignItems: 'center',
    height: 50,
  },
  mobileNav: {
    padding: '10px 0px',
    height: `calc(100dvh - 50px)`,
    overflow: 'auto',
    backgroundColor: theme.white,
    display: 'flex',
    flexDirection: 'column',
  },
}));

function MenuHeader({ children, className }) {
  const { classes, cx } = useStyles();
  return <div className={cx(classes.mobileHeader, className)}>{children}</div>;
}

function MenuBody({ children, className }) {
  const { classes, cx } = useStyles();
  return <div className={cx(classes.mobileNav, className)}>{children}</div>;
}

function BurgerComponent(props) {
  return <Burger {...props} />;
}

export default function MobileMenu({ children, className }) {
  const { classes, cx } = useStyles();
  return <div className={cx(classes.mobileWrapper, className)}>{children}</div>;
}

MobileMenu.Header = MenuHeader;
MobileMenu.Body = MenuBody;
MobileMenu.Burger = BurgerComponent;
