import { CloseButton, createStyles } from '@mantine/core';
import { Title, Text } from '../../atomic';
import { FOOTER_HEIGHT, PROGRESS_BAR_HEIGHT } from './constants';

const useStyles = createStyles((theme) => ({
  root: {
    height: `calc(100vh - ${FOOTER_HEIGHT + PROGRESS_BAR_HEIGHT}px)`,
    width: '100%',
    overflowY: 'hidden',
    // backgroundColor: theme.white,
    [theme.fn.smallerThan('xs')]: {
      padding: '0px 20px',
    },
  },
  content: {
    maxWidth: 550,
    margin: '20px auto',
    height: 'calc(100% - 88px)',
    overflowY: 'auto',
    ...theme.other.hideScrollBar,
  },
  header: {
    backgroundColor: theme.white,
    height: '48px',
    display: 'flex',
    alignItems: 'center',
    padding: '10px',
    borderBottom: '1px solid #e1e5ea',
    justifyContent: 'space-between',
  },
}));

function MainTitle(props) {
  const { classes } = useStyles();
  return (
    <div className={classes.header}>
      <Title order={4}>{props.children}</Title>
      <CloseButton size={24} color="dark" onClick={props.onClick} />
    </div>
  );
}

function SubTitle(props) {
  return (
    <Text size="sm" {...props}>
      {props.children}
    </Text>
  );
}

export default function MainContent({ title, onClose, className, children }) {
  const { classes, cx } = useStyles();
  return (
    <div className={cx(classes.root, className)}>
      <MainTitle onClick={onClose}>{title}</MainTitle>
      <div className={classes.content}>{children}</div>
    </div>
  );
}

MainContent.Title = MainTitle;
MainContent.SubTitle = SubTitle;
