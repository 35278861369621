import { Tooltip, Text, createStyles } from '@mantine/core';
// import WhatsappTick from '../../../svg/WhatsappTick';
import MessageStatus from './MessageStatus';
import DeliveryFailureStatus from './DeliveryFailureStatus';
import ChatAvatar from './ChatAvatar';
import ICONS from '@akin/core-lib/icons';

const useStyles = createStyles((theme) => ({
  container: {
    boxSizing: 'border-box',
    fontSize: '1em',
    color: 'rgba(0,0,0,.87)',
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: 'transparent',
    overflow: 'hidden',
    gap: 8,
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  footer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    margin: '4px 0',
  },
  messageContentWrapper: {
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: 5,
    padding: '8px 15px',
  },
  messageHeader: {
    marginRight: 20,
  },
  messageTime: {},
  incomingMessage: {
    marginRight: 'auto',
    maxWidth: '80%',
    '& .direction': {
      backgroundColor: theme.white,
    },
    [theme.fn.smallerThan('xs')]: {
      maxWidth: '90%',
    },
  },
  outgoingMessage: {
    marginLeft: 'auto',
    maxWidth: '80%',
    justifyContent: 'end',
    flexDirection: 'row-reverse',
    '& .direction': {
      backgroundColor: theme.white,
    },
    [theme.fn.smallerThan('xs')]: {
      maxWidth: '90%',
    },
  },
  messageAvatar: {
    boxSizing: 'border-box',
    margin: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    // width: '42px!important',
  },
  level: {
    flex: 1,
    display: 'flex',
  },
  pill: {
    backgroundColor: theme.white,
    padding: '2px 15px',
    borderRadius: 15,
    marginRight: 5,
  },
  messageRoot: {
    position: 'relative',
    '&.showFailureStatus > div:first-of-type': {
      paddingRight: 26,
    },
  },
  deliveryFailure: {
    position: 'absolute',
    top: 0,
    right: 4,
  },
  avatarSpacer: {
    width: 30,
  },
}));

const LevelLabel = ({ level, direction, levelColor, levelTooltipLabel }) => {
  const { classes } = useStyles();
  if (direction === 'outgoing') {
    return (
      <div className={classes.level}>
        <div className={classes.pill}>
          {levelTooltipLabel && (
            <Tooltip label={levelTooltipLabel}>
              <Text weight={700} fz={10} color={levelColor}>
                {level}
              </Text>
            </Tooltip>
          )}

          {!levelTooltipLabel && (
            <Text fz={8} color={levelColor}>
              {level}
            </Text>
          )}
        </div>
      </div>
    );
  }
  return null;
};

function Message({
  model: {
    sentTime,
    // sender,
    direction,
    level,
    levelColor,
    levelTooltipLabel,
    messageStatus = {},
    showAvatar = false,
    showAvatarSpacer = true,
    avatarProps = {},
  },
  children,
  className,
}) {
  const { classes, cx } = useStyles();

  return (
    <div
      className={cx(
        classes.container,
        {
          [classes.incomingMessage]: direction === 'incoming',
          [classes.outgoingMessage]: direction === 'outgoing',
        },
        className
      )}
    >
      {/* =================== MESSAGE AVATAR =================== */}
      {showAvatar ? (
        <div
          className={cx(classes.messageAvatar, {
            incoming: direction === 'incoming',
            outgoing: direction === 'outgoing',
          })}
          onDoubleClick={avatarProps?.onDoubleClick}
        >
          <ChatAvatar
            src={avatarProps?.src || ICONS.chatAvatar}
            name={avatarProps?.name}
          />
        </div>
      ) : (
        showAvatarSpacer && <div className={classes.avatarSpacer} />
      )}

      {/* ======================= MESSAGE BODY =================== */}
      <div>
        <div
          className={cx(classes.messageRoot, {
            showFailureStatus: messageStatus?.showDeliveryFailed,
          })}
        >
          {/* ----------------------- MESSAGE TEXT -------------------- */}
          <div className={cx(classes.messageContentWrapper, 'direction')}>
            {children}
          </div>

          {/* ---------------------- DELIVERY STATUS ------------------ */}
          {messageStatus?.showDeliveryFailed && (
            <DeliveryFailureStatus className={classes.deliveryFailure} />
          )}
        </div>

        {/*========================= MESSAGE FOOTER ======================*/}
        <div className={classes.footer}>
          {/* -------------------- MESSAGE TAG -------------*/}
          {level && (
            <LevelLabel
              level={level}
              direction={direction}
              levelColor={levelColor}
              levelTooltipLabel={levelTooltipLabel}
            />
          )}

          {/* -------------------- MESSAGE STATUS TICKS AND TIME ------------------*/}
          <MessageStatus
            showTick={messageStatus?.showTick}
            showDoubleTick={messageStatus?.showDoubleTick}
            isSeen={messageStatus?.showBlueTick}
            time={sentTime}
          />
        </div>
      </div>
    </div>
  );
}

export default Message;
