import { Button } from '@mantine/core';
import React from 'react';

// eslint-disable-next-line react/display-name
const ButtonComponent = React.forwardRef((props, ref) => (
  <Button radius="sm" ref={ref} {...props}>
    {props.children}
  </Button>
));

export default ButtonComponent;
