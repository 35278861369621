import {
  ActionIcon,
  Box,
  Drawer,
  Flex,
  Text,
  createStyles,
} from '@mantine/core';
import CrossIcon from '../../svg/CrossIcon';
import { useMediaQuery } from '@mantine/hooks';

const useStyles = createStyles(() => ({
  root: {
    '& .mantine-Drawer-body': {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
    },
  },
  header: {
    height: 48,
    alignItems: 'center',
    padding: '0px 16px',
    borderBottom: '1px solid #d1d5db',
  },
  footer: {
    alignItems: 'center',
    padding: 16,
    borderTop: '1px solid #d1d5db',
    backgroundColor: '#f6f7f8',
    '&.drawerFooterActions': {
      display: 'flex',
      gap: 4,
      justifyContent: 'flex-end',
    },
  },
}));

const baseId = 'custom-drawer';
const titleId = `${baseId}-title`;
const bodyId = `${baseId}-body`;

function CloseButton({ onClose }) {
  return (
    <ActionIcon
      variant="transparent"
      p={0}
      aria-label="Close"
      onClick={onClose}
      size={16}
    >
      <CrossIcon width={20} height={20} />
    </ActionIcon>
  );
}

function CustomHeader({ children, className, onClose, title, ...props }) {
  const { classes, cx } = useStyles();
  return (
    <Flex className={cx(classes.header, className)} {...props}>
      {children}
    </Flex>
  );
}

function Header({ onClose, title }) {
  return (
    <CustomHeader justify="space-between" align="center">
      <Text id={titleId} fz={18} weight={600}>
        {title}
      </Text>
      <div>
        <CloseButton onClose={onClose} />
      </div>
    </CustomHeader>
  );
}

function Footer({ children, className, drawerFooterActions, ...props }) {
  const { classes, cx } = useStyles();
  return (
    <Flex
      {...props}
      className={cx(classes.footer, { drawerFooterActions }, className)}
    >
      {children}
    </Flex>
  );
}

function Body({ children, className }) {
  return (
    <Box
      id={bodyId}
      sx={(theme) => ({
        padding: 16,
        overflowY: 'auto',
        flex: 1,
        ...theme.other.customScrollbar,
      })}
      className={className}
    >
      {children}
    </Box>
  );
}

export default function DrawerComponent({
  title = '',
  opened = false,
  position = 'right',
  size = 'xl',
  onClose,
  showDefaultHeader = true, // When using CustomHeader set this prop to false
  closeOnClickOutside = true,
  className,
  children,
  ...otherDrawerProps
}) {
  const { classes, cx } = useStyles();
  const isMobileScreen = useMediaQuery('(max-width: 830px)');
  const drawerSize = isMobileScreen ? 'full' : size;
  return (
    <Drawer
      opened={opened}
      position={position}
      size={drawerSize}
      onClose={onClose}
      closeOnClickOutside={closeOnClickOutside}
      withCloseButton={false}
      {...otherDrawerProps}
      className={cx(classes.root, className)}
    >
      {/* -----------------DEFAULT MODAL HEADER--------------- */}
      {showDefaultHeader && <Header title={title} onClose={onClose} />}
      {children}
    </Drawer>
  );
}

DrawerComponent.Header = CustomHeader;
DrawerComponent.Body = Body;
DrawerComponent.Footer = Footer;
DrawerComponent.CloseButton = Header;
