import { useRouter } from 'next/router';
import { useEffect, useMemo, useState } from 'react';
import useUser from './useUser';
import PAGE_ROUTES, {
  AUTH_PAGE_ROUTES,
  PUBLIC_PAGES,
} from '../constants/pageRoutes';
import useSpaceSettings from './useSpaceSettings';
import { getUserRole } from '../features/permissions/utils';

const FRONTDESK_USER_ALLOWED_ROUTES = [
  PAGE_ROUTES.inbox,
  PAGE_ROUTES.profile,
  PAGE_ROUTES.spaceCodeInbox,
  PAGE_ROUTES.spaceCodeInboxConversation,
];
const MANAGER_USER_ALLOWED_ROUTES = [
  PAGE_ROUTES.home,
  PAGE_ROUTES.inbox,
  PAGE_ROUTES.profile,
  PAGE_ROUTES.spaceCodeInbox,
  PAGE_ROUTES.spaceCodeInboxConversation,
];

function checkIfAkinDomain() {
  const { origin } = window.location;
  if (origin.includes('akin')) return true;
  return false;
}

function getD3xRedirectionRoute() {
  const D3X_ORIGIN_URL = {
    staging: 'https://platform.staging.d3x.ai',
    production: 'https://platform.d3x.ai',
  };
  const d3xOrigin = D3X_ORIGIN_URL[process.env.NEXT_PUBLIC_ENV];
  const { pathname } = window.location;
  const searchParams = window.location.search;
  return `${d3xOrigin}${pathname}${searchParams}`;
}

export default function useShowPage() {
  const router = useRouter();
  const { user } = useUser();
  const [showPage, setShowPage] = useState(false);
  const { userSpacePermissions } = useSpaceSettings();
  const [isRerouting, setIsRerouting] = useState(false);
  const userRole = useMemo(() => {
    return getUserRole(userSpacePermissions);
  }, [userSpacePermissions]);

  const isLoggedIn = Boolean(user.user_id);

  const rerouteTo = (page) => {
    if (isRerouting) return;
    router.replace(page);
    setIsRerouting(true);
  };

  useEffect(() => {
    // Redirect from akin to D3x domain
    if (checkIfAkinDomain()) {
      const d3xRedirectionUrl = getD3xRedirectionRoute();
      window.location.href = d3xRedirectionUrl;
      return;
    }

    if (PUBLIC_PAGES.includes(router.pathname)) {
      setShowPage(true);
      return;
    }

    if (!isLoggedIn && !AUTH_PAGE_ROUTES.includes(router.pathname)) {
      rerouteTo(PAGE_ROUTES.login);
      return;
    }

    if (isLoggedIn && AUTH_PAGE_ROUTES.includes(router.pathname)) {
      rerouteTo(PAGE_ROUTES.home);
      return;
    }

    // Redirection logic for frontdesk user route access...
    // Temporary logic. Needs to be refactored
    if (!user?.isSuperAdmin && userSpacePermissions) {
      if (
        userRole === 'frontdesk' &&
        !FRONTDESK_USER_ALLOWED_ROUTES.includes(router.pathname)
      ) {
        rerouteTo(PAGE_ROUTES.inbox);
        return;
      }

      if (
        userRole === 'manager' &&
        !MANAGER_USER_ALLOWED_ROUTES.includes(router.pathname)
      ) {
        rerouteTo(PAGE_ROUTES.home);
        return;
      }
    }

    setShowPage(true);
    setIsRerouting(false);
  }, [router.pathname, userSpacePermissions, userRole]);

  return {
    showPage,
    isLoggedIn,
    currentRoute: router?.route,
    spaceCodeQuery: router?.query?.spaceCode,
    isRouterReady: router.isReady,
  };
}
