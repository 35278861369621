import http from './axiosConfig';
import APIError from './customErrors';

export const signup = async (data) => {
  try {
    const response = await http.post(`/user/signup`, data);
    return response.data;
  } catch (error) {
    throw new APIError(error);
  }
};

export const login = async (data) => {
  try {
    const response = await http.post(`/user/login`, data, {
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    throw new APIError(error);
  }
};

export const resetDefaultPassword = async (data) => {
  try {

    const response = await http.patch(`/user/reset-default-password`, data, {
      withCredentials: true,
    });
    return response.data;
  } catch (error) {

    throw new APIError(error);
  }
}

export const logout = async () => {
  try {
    const response = await http.get(`/user/logout`, {
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    throw new APIError(error);
  }
};

export const getUserInfo = async () => {
  try {
    const response = await http.get(`/user/get-user`, {
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    throw new APIError(error);
  }
};

export const emailResetPasswordLink = async (data) => {
  try {
    const response = await http.post(`/user/request-reset-password`, data);
    return response.data;
  } catch (error) {
    throw new APIError(error);
  }
};

export const verifyResetPasswordToken = async (data) => {
  try {
    const response = await http.post(`/user/verify-reset-password-link`, data);
    return response.data;
  } catch (error) {
    throw new APIError(error);
  }
};

export const resetPassword = async (data) => {
  try {
    const response = await http.post(`/user/reset-password`, data);
    return response.data;
  } catch (error) {
    throw new APIError(error);
  }
};

export const verifyUser = async (data) => {
  try {
    const response = await http.post(`/user/verify-email`, data);
    return response.data;
  } catch (error) {
    throw new APIError(error);
  }
};
