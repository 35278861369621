import { createStyles, Progress } from '@mantine/core';
import { Button, Text } from '../../atomic';
import { FOOTER_HEIGHT, PROGRESS_BAR_HEIGHT } from './constants';

const useStyles = createStyles((theme) => ({
  root: {
    display: 'flex',
    padding: '10px 15px',
    boxSizing: 'border-box',
    alignItems: 'center',
    height: FOOTER_HEIGHT,
    // backgroundColor: theme.white,
    backgroundColor: '#f6f7f8',
  },
  progressInfo: {
    flex: 1,
    textAlign: 'center',
  },
}));

export default function Footer({
  infoText,
  displayPreviousButton = true,
  onClickNext,
  onClickPrevious,
  progressValue,
  nextButtonText = 'Next',
  previousButtonText = 'Previous',
  nextButtonType = 'button',
}) {
  const { classes } = useStyles();
  return (
    <>
      <Progress size={PROGRESS_BAR_HEIGHT} value={progressValue} />
      <div className={classes.root}>
        {displayPreviousButton && (
          <Button variant="outline" onClick={onClickPrevious}>
            {previousButtonText}
          </Button>
        )}

        <div className={classes.progressInfo}>
          <Text size="sm">{infoText}</Text>
        </div>

        <Button type={nextButtonType} onClick={onClickNext}>
          {nextButtonText}
        </Button>
      </div>
    </>
  );
}
