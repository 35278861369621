import { createStyles, useMantineTheme } from '@mantine/core';
import { Button, Text, Title, UnstyledButton } from '../../atomic';
import TickCircle from '../../svg/TickCircle';
import CircleEmpty from '../../svg/CircleEmpty';

import { SIDEBAR_FOOTER_HEIGHT } from './constants';

const useStyles = createStyles((theme) => ({
  root: {
    borderRight: '1px solid #e1e5ea',
  },
  titleContainer: {
    minHeight: '48px',
    padding: '10px',
    borderBottom: '1px solid #e1e5ea',
  },

  sidebarItem: {
    marginBottom: theme.spacing.md,
    padding: 6,
    '& > div': {
      justifyContent: 'space-between',
    },
  },
  navBody: { padding: 10 },
  footer: {
    padding: 10,
    height: SIDEBAR_FOOTER_HEIGHT,
    position: 'absolute',
    bottom: 0,
    right: 0,
    left: 0,
  },
}));

function SidebarTitle({ title, subTitle }) {
  const { classes } = useStyles();
  return (
    <>
      <div className={classes.titleContainer}>
        <Title order={4}>{title}</Title>
      </div>
      {subTitle && (
        <div className={classes.titleContainer}>
          <Text size="sm" weight={600}>
            {subTitle}
          </Text>
        </div>
      )}
    </>
  );
}

function SidebarBody({ children }) {
  const { classes } = useStyles();
  return <div className={classes.navBody}>{children}</div>;
}

/**
 * Renders either a completed (tick) or empty circle icon
 * based on the completion status
 */
function StatusIcon({ isCompleted, color }) {
  const IconComponent = isCompleted ? TickCircle : CircleEmpty;
  return <IconComponent width={20} height={20} stroke={color} />;
}

/**
 * SidebarItem component renders a button with:
 * - Dynamic styling based on active state
 * - Status icon showing completion
 * - Title text
 */
function SidebarItem({ title, isActive, isCompleted, onClick }) {
  const { classes } = useStyles();
  const theme = useMantineTheme();

  return (
    <Button
      // Changes button appearance based on active state
      variant="subtle"
      color={isActive ? 'theme-color.6' : 'gray.8'}
      className={classes.sidebarItem}
      onClick={onClick}
      rightIcon={
        <StatusIcon
          isCompleted={isCompleted}
          color={
            isActive ? theme.colors['theme-color'][6] : theme.colors.gray[8]
          }
        />
      }
      fullWidth
    >
      <Text size="xs" pl={5}>
        {title}
      </Text>
    </Button>
  );
}

function SidebarFooter({ children, className }) {
  const { classes, cx } = useStyles();
  return <div className={cx(classes.footer, className)}>{children}</div>;
}

export default function Sidebar({ children, className }) {
  const { classes, cx } = useStyles();
  return <div className={cx(classes.root, className)}>{children}</div>;
}

Sidebar.Title = SidebarTitle;
Sidebar.Item = SidebarItem;
Sidebar.Body = SidebarBody;
Sidebar.Footer = SidebarFooter;
