import { Text } from '../../atomic';
import { CloseButton, createStyles } from '@mantine/core';
import { useEffect, useState } from 'react';
import MobileMenu from '../mobileMenu';
import Sidebar from './Sidebar';
import { SIDEBAR_FOOTER_HEIGHT } from './constants';

const useStyles = createStyles((theme) => ({
  root: {
    position: 'relative',
    // backgroundColor: '#F3F4F6',
    backgroundColor: theme.white,
    height: '100%',
    paddingBottom: SIDEBAR_FOOTER_HEIGHT,
    [theme.fn.smallerThan('sm')]: {
      display: 'none',
    },
  },
  mobileNavBody: {
    paddingTop: 20,
    // backgroundColor: '#F3F4F6',
    backgroundColor: theme.white,
  },
}));

export default function SidebarWrapper({
  title,
  subTitle,
  children,
  menuID,
  onClickBack,
  footer,
}) {
  const { classes } = useStyles();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    setIsMenuOpen(false);
  }, [menuID]);

  const toggleMenu = () => {
    setIsMenuOpen((prevState) => !prevState);
  };
  return (
    <>
      <Sidebar className={classes.root}>
        <Sidebar.Title title={title} subTitle={subTitle} />
        {children}
      </Sidebar>

      <MobileMenu>
        <MobileMenu.Header>
          <MobileMenu.Burger
            opened={isMenuOpen}
            onClick={toggleMenu}
            transitionDuration={0}
          />
          <Text weight={500} size="sm">
            {title}
            {subTitle && `: ${subTitle}`}
          </Text>
          <CloseButton size={24} color="dark" onClick={onClickBack} />
        </MobileMenu.Header>
        {isMenuOpen && (
          <MobileMenu.Body className={classes.mobileNavBody}>
            {children}
          </MobileMenu.Body>
        )}
      </MobileMenu>
    </>
  );
}
