const imagePath = `${process.env.NEXT_PUBLIC_IMAGES_CDN_PATH}/icons`;

export default {
  home: `${imagePath}/home.png`,
  calendar: `${imagePath}/calendar-menu.png`,
  listings: `${imagePath}/listings.png`,
  bookingEngine: `${imagePath}/booking-engine.png`,
  payouts: `${imagePath}/payouts.png`,
  help: `${imagePath}/menu-help.png`,
  settings: `${imagePath}/menu-settings.png`,
  settingsGrey: `${imagePath}/settingsGrey.png`,
  check: `${imagePath}/check.png`,
  circle: `${imagePath}/circle.png`,
  paint: `${imagePath}/paint.png`,
  plus: `${imagePath}/plus.png`,
  plusWhite: `${imagePath}/plus-white.png`,
  plus2: `${imagePath}/plus2.png`,
  minus: `${imagePath}/minus.png`,
  minusWhite: `${imagePath}/minus-white.png`,
  search: `${imagePath}/search.png`,
  calendarIcon: `${imagePath}/calendar-icon.png`,
  loyalty: `${imagePath}/loyalty.png`,
  left: `${imagePath}/left2.png`,
  leftWhite: `${imagePath}/left-white.png`,
  right: `${imagePath}/right2.png`,
  calendar2: `${imagePath}/calendar.png`,
  calendarClock: `${imagePath}/calendar-dark.png`,
  filter: `${imagePath}/calendar-filter.png`,
  tag: `${imagePath}/tag.png`,
  time: `${imagePath}/time.png`,
  pin: `${imagePath}/location-pin.png`,
  edit: `${imagePath}/pencil.png`,
  delete: `${imagePath}/bin.png`,
  download: `${imagePath}/download.png`,
  reports: `${imagePath}/analytics.png`,
  newTab: `${imagePath}/new-tab.png`,
  copy: `${imagePath}/copy.png`,
  link: `${imagePath}/link.png`,
  globe: `${imagePath}/globe.png`,
  globeWhite: `${imagePath}/globe-white.png`,
  moon: `${imagePath}/moon.png`,
  sun: `${imagePath}/sun.png`,
  screen: `${imagePath}/screen.png`,
  screenWhite: `${imagePath}/screen-white.png`,
  expand: `${imagePath}/expand.png`,
  collapse: `${imagePath}/collapse.png`,
  refresh: `${imagePath}/refresh.png`,
  stopTrue: `${imagePath}/stop-sell-true.png`,
  stopFalse: `${imagePath}/stop-sell-false.png`,
  bulb: `${imagePath}/bulb.png`,
  idea: `${imagePath}/idea.png`,
  blackTick: `${imagePath}/black-tick.svg`,
  collaboration: `${imagePath}/collaboration.png`,
  stay: `${imagePath}/stay.png`,
  chat: `${imagePath}/chat.png`,
  concierge: `${imagePath}/concierge.png`,
  robot: `${imagePath}/robot.png`,
  drag: `${imagePath}/drag.png`,
  colorPalette: `${imagePath}/color-palette.png`,
  brainstorm: `${imagePath}/brainstorm.png`,
  handshake: `${imagePath}/handshake.png`,
  bed: `${imagePath}/bed.png`,
  handCursor: `${imagePath}/hand-cursor.png`,
  funnel: `${imagePath}/funnel.png`,
  carKey: `${imagePath}/car-key.png`,
  train: `${imagePath}/train.png`,
  walk: `${imagePath}/walk.png`,
  cycling: `${imagePath}/cycling.png`,
  representation: `${imagePath}/representation.png`,
  writing: `${imagePath}/writing.png`,
  admin: `${imagePath}/menu-admin.png`,
  customer: `${imagePath}/menu-customer.png`,
  inventory: `${imagePath}/menu-inventory.png`,
  skills: `${imagePath}/menu-skills.png`,
  inbox: `${imagePath}/menu-inbox.png`,
  dropdownFilter: `${imagePath}/dropdown-filter.png`,
  chatAvatar: `${imagePath}/man.png`,
  homeHeart: `${imagePath}/home-heart.png`,
  channels: `${imagePath}/menu-channels.png`,

  // channel templates icons
  csat: `${imagePath}/CSAT-icon.png`,
  bookingConfirmation: `${imagePath}/booking-confirmation.png`,
  preArrivalMessage: `${imagePath}/pre-arrival-message.png`,
  postStayMessage: `${imagePath}/post-stay-message.png`,
  inHouseMessage: `${imagePath}/in-house-message.png`,

  optiiReport: `${imagePath}/Reports_optii_icon.png`,
  overviewReport: `${imagePath}/Reports_overview_icon.png`,
  csatReport: `${imagePath}/Reports_csat_icon.png`,

  // emoji score icons
  emojiScore1: `${imagePath}/emoji-score-1.png`,
  emojiScore2: `${imagePath}/emoji-score-2.png`,
  emojiScore3: `${imagePath}/emoji-score-3.png`,
  emojiScore4: `${imagePath}/emoji-score-4.png`,
  emojiScore5: `${imagePath}/emoji-score-5.png`,
};
