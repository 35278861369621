import { ActionIcon, Box, Flex, Text, createStyles } from '@mantine/core';
import { useFocusTrap, useMediaQuery, useMergedRef } from '@mantine/hooks';
import { useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import CrossIcon from '../../svg/CrossIcon';

const useMainStyles = createStyles((theme, { size, fullScreen, centered }) => ({
  modalRoot: {
    position: 'fixed',
    inset: 0,
    zIndex: 200,
    display: 'none',
    '&.show': {
      display: 'block',
    },
  },
  modalOverlay: {
    inset: 0,
    zIndex: 0,
    backgroundColor: 'rgb(16, 17, 19)',
    opacity: 0.85,
    borderRadius: 0,
    position: 'fixed',
  },
  modalInner: {
    position: 'absolute',
    inset: '0px',
    overflowY: 'auto',
    ...(!fullScreen && { padding: '48px 16px' }),
    display: 'flex',
    WebkitBoxPack: 'center',
    justifyContent: 'center',
    alignItems: centered ? 'center' : 'flex-start',
  },
  modalPaperRoot: {
    position: 'relative',
    zIndex: 1,
    boxSizing: 'border-box',
    outline: 0,
    boxShadow:
      'rgba(0, 0, 0, 0.05) 0px 1px 3px, rgba(0, 0, 0, 0.05) 0px 28px 23px -7px, rgba(0, 0, 0, 0.04) 0px 12px 12px -7px',
    backgroundColor: '#fff',
    ...(!fullScreen && { borderRadius: 10 }),
    display: 'flex',
    flexDirection: 'column',
    width: size,
    maxHeight: 'calc(100vh - 100px)',
    ...(fullScreen && {
      width: '100vw',
      height: '100vh',
      maxHeight: 'unset',
    }),
    overflow: 'hidden',
  },
  footer: {
    borderTop: '1px solid #d1d5db',
    backgroundColor: '#f6f7f8',
  },
  header: {
    borderBottom: '1px solid #d1d5db',
    minheight: 48,
    '&.noBorder': {
      border: 'none',
    },
  },
}));

const baseId = 'custom-modal';
const titleId = `${baseId}-title`;
const bodyId = `${baseId}-body`;

function CloseButton({ onClose }) {
  return (
    <ActionIcon
      variant="transparent"
      p={0}
      aria-label="Close"
      onClick={onClose}
      size={16}
    >
      <CrossIcon width={20} height={20} />
    </ActionIcon>
  );
}

function CustomHeader({
  children,
  className,
  onClose,
  p = 15,
  title,
  noBorder,
  ...props
}) {
  const { classes, cx } = useMainStyles({});
  return (
    <Flex
      p={p}
      className={cx(classes.header, { noBorder }, className)}
      {...props}
    >
      {children}
    </Flex>
  );
}

function Header({ onClose, p = 15, title }) {
  return (
    <CustomHeader
      p={p}
      justify="space-between"
      align="center"
      noBorder={!title}
    >
      <Text id={titleId} fz={15} weight={600}>
        {title}
      </Text>
      <div>
        <CloseButton onClose={onClose} />
      </div>
    </CustomHeader>
  );
}

function Footer({ children, className, p = 15, ...props }) {
  const { classes, cx } = useMainStyles({});
  return (
    <Flex p={p} className={cx(classes.footer, className)} {...props}>
      {children}
    </Flex>
  );
}

function Body({ children, className, p = 15 }) {
  return (
    <Box
      id={bodyId}
      sx={(theme) => ({
        padding: p,
        overflowY: 'auto',
        height: '100%',
        ...theme.other.customScrollbar,
      })}
      className={className}
    >
      {children}
    </Box>
  );
}

export default function Modal({
  opened = false,
  size = 500,
  fullScreen = false,
  centered = false,
  showDefaultHeader = true, // When using CustomHeader set this prop to false
  title = '',
  hp,
  onClose,
  disableClickOutside = false,
  children,
  className,
  showMobileFullscreen = false,
}) {
  const isMobileScreen = useMediaQuery('(max-width: 830px)');
  const isFullScreen = (isMobileScreen && showMobileFullscreen) || fullScreen;
  const { classes, cx } = useMainStyles({
    size,
    fullScreen: isFullScreen,
    centered,
  });
  const [isMounted, setIsMounted] = useState(false);
  const clickTarget = useRef(null);
  const focusTrapRef = useFocusTrap(opened);
  const overlayRef = useRef(null);
  const mergedRef = useMergedRef(focusTrapRef, overlayRef);

  useEffect(() => {
    setIsMounted(true);

    const closeOnEscapeKeyDown = (e) => {
      if (e.key === 'Escape') {
        onClose();
      }
    };

    const setClickTarget = (e) => {
      clickTarget.current = e.target;
    };

    window.addEventListener('keydown', closeOnEscapeKeyDown);
    window.addEventListener('mousedown', setClickTarget);
    return () => {
      window.removeEventListener('keydown', closeOnEscapeKeyDown);
      window.removeEventListener('mousedown', setClickTarget);
    };
  }, []);

  const handleOutsideClick = () => {
    if (disableClickOutside) return;

    if (clickTarget.current === overlayRef.current && onClose) {
      onClose();
    }
  };

  if (!isMounted) return null;

  return createPortal(
    <div className={cx(classes.modalRoot, { show: opened })}>
      <div className={classes.modalOverlay} />

      <div
        className={classes.modalInner}
        role="presentation"
        ref={mergedRef}
        onClick={handleOutsideClick}
      >
        <div
          className={cx(classes.modalPaperRoot, className)}
          role="dialog"
          aria-modal="true"
          aria-labelledby={titleId}
          aria-describedby={bodyId}
          onClick={(e) => e.stopPropagation()}
          tabIndex={-1}
        >
          {/* -----------------DEFAULT MODAL HEADER--------------- */}
          {showDefaultHeader && (
            <Header title={title} onClose={onClose} p={hp} />
          )}

          {children}
        </div>
      </div>
    </div>,
    document.getElementById('modal-root')
  );
}

Modal.Header = CustomHeader;
Modal.Body = Body;
Modal.Footer = Footer;
Modal.CloseButton = CloseButton;
