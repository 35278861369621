import { Avatar } from '@akin/ui-lib';
import { createStyles } from '@mantine/core';

const useStyles = createStyles(() => ({}));

export default function ChatAvatar({
  avatarProps = {},
  src = null,
  initials = null,
}) {
  const { classes } = useStyles();
  return (
    <div className={classes.root}>
      <Avatar src={src} radius="xl" size={30} {...avatarProps}>
        {initials}
      </Avatar>
    </div>
  );
}
