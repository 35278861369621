import userReducer from '../features/userProfile/userSlice';
import spacesReducer from '../features/spaces/spacesSlice';
import spaceSettingsReducer from '../features/spaces/spaceSettingSlice';
import searchReducer from '../features/search/searchSplice';
import bookingMaskReducer from '../components/booking/bookingMaskSlice';
import staySetupReducer from '../components/stay_setup_wizard/staySetupSlice';
import housekeepingSetupReducer from '../components/housekeeping_wizard/housekeepingSetupSlice';

export default {
  user: userReducer,
  spaces: spacesReducer,
  spaceSettings: spaceSettingsReducer,
  search: searchReducer,
  bookingMask: bookingMaskReducer,
  staySetup: staySetupReducer,
  housekeepingSetup: housekeepingSetupReducer,
};
