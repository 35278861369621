import React from 'react';
import { createStyles } from '@mantine/core';

// import InfoIcon from '@akin/ui-lib/svg/InfoIcon';
import { Text } from '@akin/ui-lib';

const useStyles = createStyles((theme) => ({
  container: {
    padding: '10px 0px',
    display: 'flex',
    alignItems: 'stretch',
    borderRadius: '4px 0 4px 0',
    height: 60,
    [theme.fn.smallerThan('xs')]: {
      padding: 10,
    },
  },
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: 5,
    width: 'calc(100% - 250px)',
    [theme.fn.smallerThan('md')]: {
      width: 'unset',
    },
    [theme.fn.smallerThan('xs')]: {
      justifyContent: 'center',
    },
  },
  actionIcon: {
    cursor: 'pointer',
    fontSize: 14,
    color: '#868e96',
    [theme.fn.largerThan('xs')]: {
      display: 'none',
    },
  },
  name: {
    [theme.fn.smallerThan('xs')]: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      cursor: 'pointer',
    },
  },
}));

function ConversationHeaderContent({ name, info, onClick, children }) {
  const { classes } = useStyles();
  return (
    <div className={classes.headerContainer}>
      {children}

      <Text weight={600} size="sm" className={classes.name} onClick={onClick}>
        {name}
      </Text>
      <span className={classes.actionIcon} onClick={onClick}>
        ⓘ
      </span>
      <Text size={10} color="dimmed">
        {info}
      </Text>
    </div>
  );
}

function ConversationHeader(props) {
  const { children } = props;
  const { classes } = useStyles();
  return <div className={classes.container}>{children}</div>;
}

ConversationHeader.Content = ConversationHeaderContent;

export default ConversationHeader;
