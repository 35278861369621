import React from 'react';
import { Tabs } from '@mantine/core';

const defaultTabStyles = (theme) => ({
  root: {
    overflowY: 'auto',
    ...theme.other.customScrollbar,
  },
  tabsList: {
    flexWrap: 'nowrap',
  },
  tab: {
    borderWidth: 3,
    fontWeight: 600,
    fontSize: 13,
  },
});

function TabPanel(props) {
  const panelProps = { ...props };
  if (panelProps.title) delete panelProps.title;
  if (panelProps.icon) delete panelProps.icon;
  return (
    <Tabs.Panel value={props.id} {...panelProps}>
      {props.children}
    </Tabs.Panel>
  );
}

export default function TabsComponent(props) {
  return (
    <Tabs {...props}>
      <Tabs.List>
        {React.Children.map(props.children, (child) => (
          <Tabs.Tab value={child.props.id} icon={child.props.icon || undefined}>
            {child.props.title}
          </Tabs.Tab>
        ))}
      </Tabs.List>
      {props.children}
    </Tabs>
  );
}

TabsComponent.Panel = TabPanel;

TabsComponent.style = defaultTabStyles;
